.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 1.4rem;
  border-radius: 3px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  background: #313547;
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  /* font-size: 1.5rem; */
}

.Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
  /* background: linear-gradient(90deg, rgba(35, 57, 172, 1) 0%, rgba(43, 59, 147, 1) 100%); */
  background: #5e404b;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  /* background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%); */
  background: linear-gradient(150deg,#c4074d,#722378 18%,#224e8f 36%,#00aabd 54%,#52a414 72%,#ffb800 89%,#f9560a)!important;
  color: rgba(255, 255, 255, 1);
}

.Tab.block .Tab-option.active:hover {
  opacity: 1;
  pointer-events: none;
  /* background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%); */
  background: linear-gradient(150deg,#f9560a,#ffb800 18%,#52a414 36%,#00aabd 54%,#224e8f 72%,#722378 89%,#c4074d)!important;
  color: rgba(255, 255, 255, 1);
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: 1.5rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}
